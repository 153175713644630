.totalPanelBox{
  background-color: #0D47A1;
  padding: 10px 10px;
}
/* .admin-small-box{
  text
} */

.totalUsers{
  display: inline-block;
}

.totalPanel .board{
  background-color: #0D47A1;
  color: #E3F2FD;
  border-radius: 20px;
  margin: 10px;
  padding: 1rem 0.2rem;
}

.admin-white-card{
  /* background-color: #def3fd; */
  color: black;

}
.admin-white-button{
  background-color: #def3fd ;
  height: 75px;
}

.news{
  background-color: #E3F2FD;
  color: #0D47A1;
  text-decoration-color: #0d47a1;
  border-radius: 5px;
  margin: 16px;
  padding: 16px;
  border-right: 8px solid #0D47A1;
  border-left: 8px solid #0D47A1;
}
.news-scroll .text-center{
  margin: 0 16px 0px 16px;
  font-weight: bold;
  font-family: sans-serif;
  
}

.row-ann{
  color: #0D47A1;
  width: 100%;
}
.announcement-forum {
  display: flex;
  justify-content: space-evenly;
} 

.row-ann:hover{
  font-weight: bold;
  border-right: 0 solid #0D47A1;
  border-left: 0 solid #0D47A1;
  cursor: default;
}
.announcement-news-scroll {
  cursor: default;
  font-weight: bold;
  color: #0D47A1;
}

/* css for dropdown notification */

.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  /* visibility: hidden; */
  position: absolute;
  background-color: #0D47A1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  color: #CCD8EB;
  text-align: left;
  right:22px;
  border-radius: 20px;
  margin-top: 3px;
}
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  /* visibility: hidden; */
}

.dropdown a:hover {background-color: #0D47A1 ;}

.show {
  display: block;
  /* visibility: visible; */
}

/* #myDropdown{
  visibility: hidden;
} */






/* css for switch button for notification setting  */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  content: "OOO";
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  content: "OFF";

}

.slider:before {
  position: absolute;
  content: "ON";
  color: #000;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




/* CSS for small button  */

.btn .btn-sm .upload-btn .btn-ann{
  color: #0D47A1;
  border: 1px solid #0D47A1;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
  min-width: 5%;
  font-weight: bolder;

}

/*Analytics module*/
.comparechart{
  margin: 0 1rem 1rem 1rem;
  border-radius: 1rem;
}

/*application analytics*/
.quality .box{
    background: #FFF;
    color: #0D47A1!important;
    border-radius: 10px; 
    margin: 20px 0;
    padding: 1.5vw;
    text-align: center;
    min-height: 65px;
    
}
.quality .box-title{ 
  color: #0D47A1!important;
  margin: 0.4rem 0rem;
}

.quality .box-text{ 
  color: #0D47A1!important;
  font-weight: bolder;
  font-size: 30px;
  margin: 0.4rem 0.5rem;
}


/*leader of the month*/
/* .table{
  table-layout: auto;
  color: #0D47A1;
  text-align: center;
  font-weight: bolder;
  margin: 0.4rem 0.5rem;
  border-radius: 10px;
  padding: 5px;
  border-collapse: inherit;
  }

.table td{
  vertical-align: middle;
  padding: 0.5rem;
}
.table tr{
  border: none;
  background-color: #E3F2FD;
}

.table tr:hover{
  border: none;
  background-color: #E3F2FD;
} */

.Quarterboard .list-group-item{
  background-color: #E3F2FD !important;
  color: #0D47A1;
  font-weight: bolder;
  margin: 0.15rem 0.15rem;
  border-radius: 10px;
  text-align: left;
  vertical-align: middle;
}

.Quarterboard .secPic{
  width: 2vw;
  height: 2vw;
}

/*CSS for role assign*/

#role-search-btn{
  background: #ffffff;
  color: #0D47A1;
  margin: 0 0.4rem 0 0.5rem;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  border-radius: 40px;
}

.toggle-container{
  position: relative;
  width: 100px;
  height: 32px;
  background-color: #92b0b3;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;

}

.toggle-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 38px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #dde6ec;
  background-color: #92b0b3;
  box-shadow: 0 2px 4px black;
  padding: 8px 12px;
  border-radius: 3px;
  transition: all 0.2s ease;
  left: 62px;
  font-weight: bold;
  position: absolute;
}

.disable{
  background-color: #66104c;
  left:0px ;
}



/* css for toggle switch  */

.container-toggle {
  text-align: center;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 5px;
  margin-bottom: 8px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  padding-bottom: 10px;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "Admin";
  padding-left: 10px;
  background-color: #060;
  color: #fff;
}
.inner:after {
  content: "user";
  padding-right: 14px;
  padding-bottom: 5px;
  background-color: #92b0b3;
  color: #000;
  text-align: right;
}
.switch {
  display: block;
  width: 14px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 5px;
  bottom: 10px;
  right: 55px;
  border: 0 solid #bbb;
  border-radius: 15px 15px 15px 15px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0px;
}
.checkbox:checked + .label .switch {
  right: -3px;
}


/* random css  */

.someee{
  margin-top: 20px;
  margin-left: 0;
}

.yo{
  margin-top: 20px;
}

img{
  object-fit:cover;
}
a:hover{
  text-decoration: none;
}
#main{
  margin: 5.5rem 1.5rem;
}
.navbar-brand img{
  width: 70px;
}
.search-form {
  justify-content: space-around;
}
#search-bar{
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 46.2vw;
  height: 2rem;
  color: #CCD8EB;
  border: 2px solid #CCD8EB;
  margin-bottom: 1rem;
  border-right: none;
}
#search-btn{
  background: #CCD8EB;
  color: #0D47A1;
  margin: 0 0 1rem 0;
  /* padding: 0.8rem 1rem; */
  border-left: none !important;
  text-align: -webkit-auto;
  height: 2rem;
  width: 3rem;
  font-size: 1rem;
  border: 2px solid #CCD8EB;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
#notification-btn{
  background: #0D47A1;
  color: #ffffff;
  margin: 0 0 0 0.3rem;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border-radius: 30px;
}
.dropdown-menu{
  width: 20vw;
  height: auto;
  overflow-y: scroll;
  padding: 1rem;
}
.dropdown-header {
  font-size: 16px;
  color: #0D47A1;
}
.dropdown-subheader {
  font-size: 13px;
  font-weight: 400;
  color: #0D47A1;
}
.drplft .dropdown-toggle::after{
  display: none;
}
.dropdown-item{
  background-color: #0D47A1;
  color: #fff;
  padding: 0.4rem;
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  width: 50%;
}
.dropdown-item:hover {
  background-color: #ad0303;
  color: #fff;
}
#content{
  padding: 0rem 1rem 1rem 1.5rem;
}
#content-card{
  background-color: #0D47A1;
  color:#ffffff;
  min-height: 67vh;
  border-radius: 20px;
  /* box-shadow: -7px -7px 15px #00000034; */
  padding: 1rem 1rem 1rem 1rem;
}
#content-card .headtxt{
  margin: 0 1rem 1rem 1rem;
  color: #ffffff;
  font-weight: bolder;
  font-size: 1.5vw;
}
#content-card .headBtn{
  background: #0D47A1;
  color: #ffffff;
  font-weight: bold;
  margin: 0 0.5vw 0 0 ;
  font-size: 0.8vw;
}

#content-card .headBtn:hover, #content-card .active-option{
  background: #E3F2FD;
  color: #0D47A1;
}
#content-card .btnGroup{
  margin: 0rem 1rem;
  width: 100%;
}
#content-card .btn-group .btn{
  border: 1px solid #E3F2FD;
  margin-right: 0;
  font-size: 0.8vw;
}
#content-card .btn-group{
  margin-right: 2rem;
}
#content-title{
  color: #ffffff;
  width: 95%;
  margin: 0 1rem 1rem 1rem;
}
#dashboard-title{
  font-weight: bold;
  font-size: 1.5vw;
}
#cards{
  margin: 1rem 0;
}
#cards .row .cardGroup{
  margin: 1rem 0;
}
.list-item{
  /* margin: 0.5rem 0; */
  border-radius:10px;
  margin-top: 1rem;
  margin-left: 0rem;
  margin-right: 0rem;
  width: 100%;
}
.list-name {
  padding: 0 0.2rem;
  width: 100%;
}
.list-item .img-col{
  background: #ffffff;
  border-radius: 10px;
  padding: 0.5rem 0;
}
.list-item .social-btns{
  background: #ffffff;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}
.list-item .list-img{
  width: 2vw;
  height: 2vw;
}
.list-item .fa{
  font-size: 1vw;
}
.list-item .fa span{
  font-size: 0.9vw;
  font-family: sans-serif;
  margin-left:0.1rem;
}
.list-item-name{
  color: #0D47A1;
  font-size: 0.9vw;
  font-weight: bolder;
  background: #ffffff;
  border-radius: 10px;
  padding: 0.8vw 1rem;
  width: 200%;
}
.list-item-name abbr{
  text-decoration: none;
  cursor:pointer;
}
#cards .card{
  border-radius: 10px;
}
#upload-btn{
  background: #ffffff;
  color: #0d48a148;
  padding: .3rem 2rem;
  padding-left: 0.5rem;
  border-radius: 20px;
  font-weight: bolder;
}
.upload-icon{
  color: #0D47A1;
  font-weight: bolder;
  font-size: 1.5rem !important;
}

.sideProfile *{
  border: 0;
}
.sideProfile{
  color: #0D47A1;
}
.sideProfile p{
  margin: .2rem 0;
}
.sideProfile .h3{
  font-size: 1.5vw;
  font-weight: bold;
  color: #0D47A1;
}
.sideProfile .h3:hover{
  color: #0D47A1;
}
.sideProfile .h5{
  font-size: 1.2vw;
  text-decoration: none;
  color: #0d47a1;
  cursor: pointer;
}
.sideProfile .h6{
  font-size: 1vw;
}
.sideProfile .card-img-top{
  padding: 1rem;
  border-radius: 50%;
  border: 3px solid #0D47A1;
  font-weight: bolder;
  width: 12vw;
  height: 12vw;
}
.signout-btn{
  color: #0D47A1;
  border: 1px solid #0D47A1;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
}
.signout-btn:hover{
  background-color: #0D47A1;
  color: #E3F2FD;
}
.upload-btn{
  color: #0D47A1;
  border: 1px solid #0D47A1;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
  min-width: 20%;
  font-weight: bolder;
}
.upload-btn:hover{
  box-shadow:inset 0px 0px 0px 2px #ffffff;
  background: none;
  color: #ffffff;
}
.profile-options{
  font-weight: bold;
  font-size: 0.9vw;
  text-align: left;
}
.profile-logo{
  color: #0D47A1;
  background: #E3F2FD;
  padding: 0.2rem 1rem;
  border-radius: 10px;
}
.sideProfile .list-group-item{
  margin: 0.15rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  color: #0D47A1;
  background: #E3F2FD;
  padding: 0.75rem 0rem;
  border-right: 8px solid #0D47A1;
  border-left: 8px solid #0D47A1;
}
.sideProfile .list-group-item:hover{
  font-weight: bold;
  border-right: 0 solid #0D47A1;
  border-left: 0 solid #0D47A1;
}
.card-group{
  color: #E3F2FD;
}
.card-group .col-md-6{
  margin: 1rem 0;
}
.card-group .card{
  background-color: #E3F2FD;
}

.login,
.image {
  min-height:90vh;
}

.bg-image {
  margin: -5rem 0;
  background-image: url('https://images.unsplash.com/photo-1537884944318-390069bb8665?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGNvZGV8ZW58MHx8MHx8&w=1000&q=80');
  background-position: top;
  background-size: cover;
}
.login-head{
    font-weight: bolder;
    font-size: 2rem;
    color : #0D47A1;
    margin: 0.5rem 1rem;
}

.login-para{
    color: #0D47A1;
    margin: 0.5rem 1rem;
    margin-bottom: 1rem;
}
#login-form input[type='email'],#login-form input[type='password']{
      height: 4rem;
      background-color: #E3F2FD;
      color: #0D47A1;
      border: 0;
      width: 100%;
      border-radius:10px;
}

.side-1{
    background-color: #ffffff;
}
#loginBtn{
    background-color: #0D47A1;
    color: #E3F2FD;
    border-radius: 10px;
    width: 40%;
}
#loginBtn:hover{
  background-color: #E3F2FD;
  color: #0D47A1;
  border: 2px solid #0D47A1;
}
#login-form input[type='checkbox']{
  border:1px solid #0D47A1;
}
#login-form .form-check-label{
    font-size: 0.9rem;
    color: #0D47A1;
    font-weight: bold;
}


#uploadFile{
    padding: 0rem 1rem;
}
label{
    color: white;
    font-size: 1vw;
    margin-top: 0.5rem;
}
#uploadFile input, #uploadFile textarea{
  box-shadow:  -7px -7px 14px #0D47A1,
  7px 7px 14px #0f51b8;
}

.or{
  font-weight: bolder;
  color: #ffffff;
}

.files input {
  outline: 2px dashed #0D47A1;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;    
  margin: 0;
  font-size: 0.8rem;
  box-shadow:  -7px -7px 14px #0D47A1,
  7px 7px 14px #94a8c8;
  color: #0D47A1;
  font-weight: bolder;
}
/* .jarFile input{
  padding: 120px 0px 85px 15%; 
} */
.files input[type=file]::file-selector-button {
  background-color: #0D47A1;
  color: #ffff;
  border: none;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  
  pointer-events: none;
  position: absolute;
  top: 5rem;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.files:before {
  position: absolute;
  top: 8.5rem;
  left: 0;  
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: "Drag a file or Click Here to upload";
  display: block;
  margin: auto;
  color: #0D47A1;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 0.2rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2196F3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCD8EB;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0D47A1;
}


.leaderboard .board{
  background-color: #0D47A1;
  color: #E3F2FD;
  border-radius: 20px;
  margin: 0;
  padding: 0rem 0.2rem;
  min-height: 67vh;
  display: flex;
  justify-content: space-between;
  
}
.leaderboard .card-title{
  font-weight: bolder;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.firstProfile{
  margin: 0.25rem 0 0.25rem 0;
}
.firstProfile p {
  margin-bottom: 0.5rem;
}
.firstProfileName{
  color: #ffffff;
  font-size: 1vw;
  width: 100%;
}
.firstProfileName p {
  margin-bottom: 0;
}
.firstProfileName:hover {
  color: #ffc107;
}
.otherProfileName{
  font-size: 1vw;

}
.firstProfile .firstPic{
  border: 0.1rem solid #ffffff;
  padding: 0.1rem;
  width: 5vw;
  height: 5vw;
}
.leaderboard .list-group {
  margin-bottom: 1rem;
}

.leaderboard .list-group-item{
  background-color: #E3F2FD !important;
  color: #0D47A1;
  font-weight: bolder;
  margin: 0.31rem 0.5rem;
  border-radius: 30px;
}

.leaderboard .secPic{
  width: 2vw;
  height: 2vw;
}

.refresh {
  color: #E3F2FD;
}

.trending .card{
  border: 0;
}
.trending .card-title{
  color: #0D47A1;
}
.tags{
  margin: 0rem;
  
}
.tags .badge{
  margin: 0.3rem 0.3rem 0.3rem 0; 
  color: #ffffff;
  padding: 0.5rem;
}
.list-group{
  border: 0;
}
.user-details .col-md-9 {
  max-width: 73%;
}
.profile-details{
  padding: 1rem 0rem;
}
.profile-img{
  border: 3px solid #ffffff;
  padding: 1rem;
  width: 10vw;
  height: 10vw;

}
.profile-name{
  font-weight: bolder;
}
.profile-info{
  margin:1rem 0;

}

.profile-info a{
  text-decoration: none;
  color: #fff;
}
.profile-info span{
  margin: 1rem 1.2rem 0 0;
  font-size: 0.9rem;
}
.profile-info i{
  font-weight: bold;
}
.fields-tags{
  margin-left: 0rem;
  margin-top: 0;
}
.fields-tags .badge{
  margin: 0.5rem 0.4rem 1rem 0; 
  color: #ffffff;
  padding: 0.5rem;
}
.profile-desc{
  font-size: 0.9rem;
}
.user-stats{
  margin: 1.2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ffffff;
}
.user-stats .col-md-8{
  padding-left: 1rem;
}

.user-stats .stat-icon i{
  font-size: 1rem;
  border-radius: 50%;
  background-color: #E3F2FD;
  color: #0D47A1;
  padding: 0.6rem;
  font-weight: bolder;
}
.user-stats .stat-num{
  font-weight: bolder;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.user-stats .stat-detail{
  margin-top: 0;
  font-size: 0.6rem;
  font-weight: bold;
}
.file-stats-head {
  font-weight: bolder;
  font-size: 1rem;
}
.component-stats-num{
  background-color: #E3F2FD;
  border-radius: 20px;
  font-weight: bolder;
  color:#0D47A1;
  border: 1px solid #0D47A1;
  cursor: initial !important;
}
.component-stats-num:hover{
  background-color: #fff;
}
.component-stats-num span{
  cursor: initial;
}
.top-components{
  padding: 1rem;
}
.top-components .card{
  background: none;
  border: 3px solid #E3F2FD;
  border-radius: 20px;
  font-weight: bolder;
  font-size: 1.2rem;
}
.file-components{
  padding: 2rem 0 ;
  justify-content: center;
}
.file-components .card{
  background: none;
  border: 3px solid #0D47A1;
  border-radius: 10px;
}
.file-components .card-body {
  padding: 0.2rem;
}
.file-component-name{
  font-size: 0.1rem;
  font-weight: 600;
}
.file-components .card:hover{
  background: #E3F2FD;
  color: #0D47A1;
}
.component-stats-head{
  font-weight: bolder;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.search-query{
  margin-left: 1rem;
  font-weight: lighter;
}

.settings-profile{
  margin: 0.5rem 0;
  
}
.settings-user-details {
  padding: 0rem 1rem;
}
.settings-user-img {
  margin-bottom: 0;
}
.update {
  margin: 1.2rem 1rem;
}
.settings-profile input[type="file"]{
  background: none;
  border: none;
  margin: auto;
  color: #ffffff;
}
.settings-profile input[type=file]::file-selector-button{
  background-color: #ffffff;
  color: #0d47a1;
  border: none;
  font-size: 0.8rem;
  border-radius: 10px;
}
.settings-img{
  width: 10vw;;
  height: 10vw;
  padding: 0rem;
  margin: 0.5rem 0rem;
  
}
.login-page{
  min-height: 100vh;
}

.chg-btn{
  background: #E3F2FD;
  color: #0D47A1;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;

}
.settings-save{
  color: #ffffff;
  font-weight: bolder;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}
.settings-notif{
  border-radius: 15px;
  margin: 2rem;
  padding: 1rem;
  width: 100%;
  color: #0D47A1;
  font-weight: bold;
}
.settings-notif form{
  width: 100;
}
.settings-notif input[type="checkbox"]{
  background: #0D47A1;
}
.notifs{
  color: #0D47A1;
  font-size: 1rem;
  padding: 0 1rem;
}


/* Individual Card */

.individual-card{
  width: auto;
  height: 100%;
}
.individual-card .panel-google-plus {
  position: relative;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding: 1rem;
  min-width: 90%;
  height: 100%;
}
.cardUser h3{
  font-size: 0.8vw;
  color: #0D47A1;
  text-decoration: none;
  font-weight: bolder;
}

.singleCardUser h3{
  font-size: 1vw;
  color: #0D47A1;
  text-decoration: none;
  font-weight: bolder;
}
.individual-card .panel-google-plus > .panel-heading {
  border-width: 0px;
}
.individual-card .panel-footer {
  background-color: #fff;
  border-width: 0px;
  color: #0D47A1;
}

.individual-card .panel-google-plus > .panel-heading {
  padding-bottom: 5px;
}
.individual-card .panel-google-plus > .panel-heading > img {
  margin-right: 10px;
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
}
.individual-card .panel-google-plus > .panel-heading > h3 {
  margin: 0px;
  color: #0d47a1;
  font-size: 14px;
  font-weight: 700;
}
.individual-card .panel-google-plus > .panel-heading > h5 {
  color: #0d47a1;
  font-size: 0.6vw;
  
}
.individual-card .panel-google-plus > .panel-body {
  padding-top: 2px;
  font-size: 13px;
  padding-bottom: 0;
  margin-bottom: 0;
  background-color: #ffffff;
}
.individual-card .panel-google-plus > .panel-body h2 a abbr{
  font-size: 1vw;
  color: #0d47a1;
  font-weight: bold;
  margin: 10px 0 10px 0;
  text-decoration:none;
  cursor: pointer;
}
.individual-card .panel-google-plus > .panel-body h2{
  font-size: 1rem !important;
  color: #0d47a1;
  font-weight: bold;
  margin: 10px 0 10px 0;
  text-decoration:none;
}
.individual-card .panel-google-plus > .panel-body > p {
  text-align: justify;
  color: #0d47a1;
}
.individual-card .panel-google-plus > .panel-footer > .btn {
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 5px;
  border-radius: 30px;
  color: #0D47A1;
}
.individual-card .panel-google-plus > .panel-footer > .btn > .bi {
  margin: 2px;
  margin-right: 10px;
}

.individual-card .panel-google-plus .btn {
  border-radius: 10px;
}
.individual-card .panel-google-plus .btn-default {
  border: 2px solid #E3F2FD;
  pointer-events: none;
  background-color: #E3F2FD;
}
.individual-card .panel-google-plus .btn-default:hover, .individual-card .panel-google-plus .btn-default:focus, .individual-card .panel-google-plus .btn-default:active {
  background-color: #fff;
  border-color: #000;
}
.individual-card .tags {
  margin: 0rem;
}
.individual-card .tags .badge {
  margin: 0.3rem 0.3rem 0.3rem 0;
  color: #fff;
  padding: 0.5rem;
  background-color: #ffc107;
}
.error-message{
  color: #ffffff;
  margin: 1rem;
}

/* Single Component */
.single-component .card{
  color: #0D47A1;
  margin: 0rem 1rem;
  padding: 0.5rem 0 0.5rem 0;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 20px;
  width: 100%;
}Z
.single-component .panel-google-plus > .panel-body > .row > .col > p {
  text-align: justify;
  color: #0d47a1;
  font-size: 13px;
  font-weight: 400;
  margin-right: 20px;
  padding-top: 10px;
}
.single-component .panel-google-plus > .panel-body > .row > .col > h2 {
  margin: 0px;
  padding: 1rem 0 1rem 0;
  font-size: 20px;
  font-weight: 700;
  color: #0d47a1;
}
.single-component .panel-google-plus > .panel-body > .row > .col > .row > .pom-box {
  background-color: #e7effc;
  padding: 2rem;
  margin-right: 20px;
  border-radius: 8px;
  width:100%;
  height: 30vh;
}
.single-component .panel-google-plus > .panel-body > .row > .col > .row > .pom-box > pre{

  overflow: scroll;
  font-size: 0.8rem;
}
.single-component .panel-google-plus > .panel-body > .row > .col > .row > .pom-box > h5 {
  font-size: 15px;
  font-weight: bold;
  color: #0d47a1;
}
.single-component .panel-google-plus > .panel-body > .row > .col > .row > .pom-box > p {
  font-size: 12px;
  color: #0d47a1;
}
.single-component .fa {
  color: #0d47a1;
}
.single-component .panel-google-plus > .panel-body > .panel-google-plus-image {
  display: block;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
}

.single-component .panel-google-plus > .panel-footer {
  font-size: 14px;
  font-weight: 700;
  min-height: 50px;
  padding-top: 5px;
  margin-bottom: 5px;
  color: #0d47a1;
}
.single-component .panel-google-plus > .panel-heading {
  margin: 0 0 1rem 0;
  padding-bottom: 5px;
}
.single-component .panel-google-plus > .panel-heading > img {
  margin-right:10px;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
}
.single-component .panel-google-plus > .panel-heading > h3 {
  margin: 0px;
  color: #0d47a1;
  font-size: 1.1rem;
  font-weight: 500;
}
.single-component .panel-google-plus > .panel-footer > .btn i {
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 5px;
  border-radius: 30px;
  color: #0d47a1;
}
.single-component .file-btn small{
  font-weight: bolder;
  color: #0d47a1;
}
.single-component .file-btn small:hover{
  text-decoration: none;
}
.single-component .social{
  background-color: #E3F2FD;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow:  5px 5px 12px #cfdce6,
  -5px -5px 12px #f7ffff;

}
  .single-component .panel-google-plus .btn-default {
    color: #0D47A1;
    width: 100% ;
  }
.single-component .panel-google-plus .btn-default:hover, .single-component .panel-google-plus .btn-default:focus, .single-component .panel-google-plus .btn-default:active {
  background-color: #fff;
  border-color: #000;
}
.single-component .tags {
  margin: 0rem;
}
.single-component .tags .badge {
  margin: 0.3rem 0.3rem 0.3rem 0;
  color: #fff;
  padding: 0.5rem;
  background-color: #ffc107;
}

.single-component .post-comment .btn{
  background-color: #0D47A1;
  color: #fff;
}
.single-component .panel-google-plus .btn-default {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.104) 2px 2px 0px 0px;
  width: 100%;
}
.single-component .post-comment input{
  border-radius: 10px;
  border: 0.2px solid #0D47A1   ;
}
.single-component .top-comment {
  background-color: #e7effc;
  border-radius: 10px;
  margin: 0.2rem 0 0.4rem 0;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
}
.single-component .top-comment .name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #0D47A1;
}
.single-component .top-comment .name a{
  color: #0D47A1;
}
.single-component .top-comment p {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
}
.social-buttons .col-md-1{
  padding: 0;
  margin: 1rem;
  
}
.comment-del{
  cursor: pointer;
}
.comment-section {
  width: 100%;
}
.comment-section .showcomments {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
  cursor: pointer;
}
.uploadMsg{
  padding: 10rem 0;
}
.similar-component{
  margin: 2rem 0.8rem;
  width: 100%;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
  background: none;
  border: none;
  filter: invert(60%);
}
@keyframes sparkle {
  0%   {color: #ffffff;}
  50%  {color: #ffc107;}
  100% {color: #ffffff;}
}
.fa-diamond{
  animation-name: sparkle;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.top-component-name{
  font-size: 1vw;
}
.top-component-name a{
  color: #ffffff;

}
.top-component-name a:hover{
  color: #ffc107;

}
.spinner-container{
  height: 60vh;
  display: flex;
  align-items: center;

}
.loading-spinner {
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#wait-loader{
  z-index: 999999999;
  position: fixed;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(2, 66, 178, 0.6);
  display: flex;
}

.deleteMsg{
  min-height: 70vh;
}
.admin-body{
  margin:16px 16px 16px 0;
}

html, body{
  overflow-x: hidden;
}